import React from "react"
import BlurredSection from "../components/BlurredSection"
import Container from "../components/Container"
import Row from "../components/Row"
import Col from "../components/Col"
import Title from "../components/Title"
import styled from "styled-components"
import Paragraph from "../components/Paragraph"
import ArrowCta from "../components/ArrowCta"

const ThankYou = () => {
  return (
    <BlurredSection>
      <Container>
        <RowStyled justify="center" mb={4}>
          <Col lg={8}>
            <Title size={2} lg="d3">
              Thank you for getting in touch!
            </Title>
            <Paragraph>Our experts will reach out to you soon.</Paragraph>
            <br />
            <br />
            <ArrowCta to="/" title="Return to Homepage" />
          </Col>
        </RowStyled>
      </Container>
    </BlurredSection>
  )
}

const RowStyled = styled(Row)`
  text-align: center;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 40px;
`

export default ThankYou
